/** @type {string[]} */ "use strict";
var locales = [
    "en",
    "en-US",
    "en-AU",
    "en-GB",
    "en-CA",
    "en-NZ",
    "en-IN",
    "en-HK",
    "en-SG",
    "en-IE",
    "en-DE",
    "en-NL",
    "en-SE",
    "en-NO",
    "en-FR",
    "en-CH",
    "en-BE",
    "en-AT",
    "en-ES",
    "en-DK",
    "en-FI",
    "en-IT",
    "en-JP",
    "en-IL",
    "en-KR",
    "en-PT",
    "en-BR",
    "en-CZ",
    "en-PL",
    "en-PH",
    "de",
    "de-DE",
    "de-CH",
    "de-AT",
    "nl",
    "nl-NL",
    "nl-BE",
    "sv",
    "fr",
    "fr-FR",
    "fr-CA",
    "fr-CH",
    "fr-BE",
    "no",
    "es",
    "es-ES",
    "da",
    "pt",
    "pt-PT",
    "pt-BR",
    "fi",
    "cs",
    "pl",
    "ja",
    "it",
    "he",
    "ko", 
];
/** @type {string} */ var defaultLocale = "en";
/** @type {string[]} */ var PLEDGE_LOCALES = [
    "en",
    "nl",
    "es",
    "de",
    "fr",
    "pt",
    "pt-BR",
    "ja",
    "ko"
];
/** @type {Object.<string, string>} */ var localeNames = {
    en: "English",
    "en-US": "English (US)",
    "en-AU": "English (AU)",
    "en-GB": "English (GB)",
    cs: "Čeština - Czech",
    da: "Dansk - Danish",
    de: "Deutsch - German",
    es: "Espa\xf1ol - Spanish",
    fi: "Suomi - Finnish",
    fr: "Fran\xe7ais - French",
    he: "עברית - Hebrew",
    it: "Italiano - Italian",
    ja: "日本語 - Japanese",
    ko: "한국어 - Korean",
    nl: "Nederlands - Dutch",
    no: "Norsk - Norwegian",
    pl: "Polski - Polish",
    pt: "Portugu\xeas - Portuguese",
    "pt-BR": "Portugu\xeas (BR) - Portuguese (Brazilian)",
    sv: "Svenska - Swedish"
};
// Country flag shown to indeicate languages
/** @type {Object.<string, string>} */ var languageCountryFlags = {
    en: "US",
    cs: "CZ",
    da: "DK",
    de: "DE",
    es: "ES",
    fi: "FI",
    fr: "FR",
    he: "IL",
    it: "IT",
    ja: "JP",
    ko: "KR",
    nl: "NL",
    no: "NO",
    pl: "PL",
    pt: "PT",
    sv: "SE"
};
/** @type {string} */ var languageCookieName = "LOCALE_LANGUAGE_V2";
/** @type {string} */ var countryCookieName = "LOCALE_COUNTRY_V2";
/** @type {string[]} */ var HOMEPAGE_LANGUAGES = [
    // Languages that have a homepage
    "en", 
];
module.exports = {
    locales: locales,
    PLEDGE_LOCALES: PLEDGE_LOCALES,
    localeNames: localeNames,
    languageCountryFlags: languageCountryFlags,
    languageCookieName: languageCookieName,
    countryCookieName: countryCookieName,
    HOMEPAGE_LANGUAGES: HOMEPAGE_LANGUAGES,
    defaultLocale: defaultLocale
};
