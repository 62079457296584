export var DEFAULT_COOKIE_OPTIONS = {
    path: "/",
    maxAge: 60 * 60 * 24,
    secure: "production" !== "development",
    sameSite: "lax"
};
export var getCookieValue = function(name) {
    var ref;
    if (typeof document === "undefined") return;
    var cookieValue = (ref = document.cookie.split("; ").find(function(row) {
        return row.startsWith("".concat(name, "="));
    })) === null || ref === void 0 ? void 0 : ref.split("=")[1];
    return cookieValue;
};
export var setCookie = function(name, value) {
    var maxAge = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 31536000;
    if (typeof document === "undefined") return;
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#write_a_new_cookie
    document.cookie = "".concat(name, "=").concat(value, "; max-age=").concat(maxAge, "; path=/");
};
export var deleteCookie = function(name) {
    if (typeof document === "undefined") return;
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#example_4_reset_the_previous_cookie
    document.cookie = "".concat(name, "=; expires=Thu, 01 Jan 1970 00:00:00 GMT");
};
