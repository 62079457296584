/**
 * The error from a try/catch block is typed as unknown because it's possible to
 * throw any object. This function takes an unknown thrown object, and turns it
 * into a standard Error, with a `message` prop.
 *
 * If the thrown object is not an error, the function looks for common places
 * that an error message might be stored, and tries to coerce them to an `Error`
 *
 * If the object has additional properties, they are added as properties of the
 * `Error` object, but these properties will not appear in the typing
 */
import get from 'lodash/get'

const COMMON_ERROR_KEYS = [
  'message',
  'error',
  'err',
  'body.error',
  'body.message',
  'body',
] as const

export class ParsedError extends Error {
  constructor(err: unknown) {
    // If we already have an error, just copy it
    // Note: returning it causes a `_this is undefined` error
    if (err instanceof Error) {
      super(err.message)
      this.stack = err.stack
      this.name = err.name
    } else {
      let message = 'Unknown error'

      // check if we have a regular object
      if (typeof err === 'object' && !Array.isArray(err) && err !== null) {
        let possibleMessage
        // check common error paths to see if there is a string we can use as the error message
        for (const testKey of COMMON_ERROR_KEYS) {
          possibleMessage = get(err, testKey)
          // if we find a string key, assign it to `message` and move on
          if (typeof possibleMessage === 'string') {
            message = possibleMessage
            break
          }
        }
      }

      // fall-through if we couldn't set a message
      // just stringify the whole error and set it as the message
      if (!message) {
        message = JSON.stringify(err)
      }
      // set the message on the base class
      super(message)
    }
  }

  public properties?: Record<string, unknown>
}
