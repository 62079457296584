/**
 * Define the useWithPerson utility
 *
 * This is a separate file, because keeping it in the utils index file causes
 * some kind of circular dependency issue which leads to a "Cannot access
 * usePerson before initialization" error
 */
import { usePerson } from '@aletheia/common/components/PersonProvider'

/**
 * Utility to automatically add a personId variable to a query, or to skip the
 * query if there's no Person
 */
export const useWithPerson = ():
  | { skip: true }
  | { variables: { personId: string } } => {
  const { Person } = usePerson()
  if (!Person) {
    return {
      skip: true,
    }
  }
  return {
    variables: {
      personId: Person.id,
    },
  }
}
