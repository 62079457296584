import { ApolloProvider, NormalizedCacheObject } from '@apollo/client'

import React from 'react'

import { useApollo } from '../../lib/apollo'

type ApolloProviderProps = {
  state: NormalizedCacheObject
}

const ApolloProviderWrapper: React.FC<ApolloProviderProps> = ({
  state,
  children,
}) => {
  const client = useApollo(state)
  return (
    <>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </>
  )
}

export default ApolloProviderWrapper
