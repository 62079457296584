import { useEffect, useState } from "react";
import { theme } from "../tailwind.config";
// Returns the next breakpoint
// e.g. nextBreakpoint('sm') returns 'md'
function nextBreakpoint(currentBreakpoint) {
    var keys = Object.keys(theme.screens);
    var index = keys.indexOf(currentBreakpoint);
    return keys[index + 1];
}
function getPxValue(breakpoint, direction) {
    if (direction === "up") {
        if (breakpoint.endsWith("px")) return breakpoint;
        return theme.screens[breakpoint];
    } else {
        if (breakpoint.endsWith("px")) return "".concat(breakpoint);
        return "calc(".concat(theme.screens[nextBreakpoint(breakpoint)], " - 1px)");
    }
}
// Returns breakpoint rule for matchMedia
// e.g. getBreakpointRule('md', 'up') returns `(min-width): 768px`
function getBreakpointRule(breakpoint, direction) {
    var value = getPxValue(breakpoint, direction);
    var rule = direction === "up" ? "min" : "max";
    return "(".concat(rule, "-width: ").concat(value, ")");
}
// Hook to check if page matches tailwind screen breakpoints
//
// For example for large sceens or bigger (lg, xl, 2xl):
//   const isLargeScreen = useBreakpoint('lg', 'up')
//
// or for small screens and smaller (xs and sm):
//   const isSmScreen = useBreakpoint('sm', 'down')
export function useBreakpoint(breakpoint, direction) {
    var defaultValue = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    var ref = useState(function() {
        return defaultValue;
    }), match = ref[0], setMatch = ref[1];
    useEffect(function() {
        if ("object" === "undefined") return;
        var mediaQueryList = window.matchMedia(getBreakpointRule(breakpoint, direction));
        if (mediaQueryList.matches) setMatch(true);
        var screenTest = function(e) {
            return setMatch(e.matches);
        };
        mediaQueryList.addEventListener("change", screenTest);
        return function() {
            return mediaQueryList.removeEventListener("change", screenTest);
        };
    }, [
        breakpoint,
        direction
    ]);
    return match;
}
