import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import React, { useEffect } from "react";
import { LOCALSTORAGE_BASE_NAMESPACE } from "@aletheia/common/utils/hooks";
var SESSION_DURATION = 24 * 60 * 60 * 1000 // 24 hours in milliseconds
;
var TRACK_INTERVALS = [
    10,
    30,
    60,
    90,
    180,
    240,
    480,
    600
];
/**
 * TimeOnSiteTracking: A Compontent that tracks user time spent on site across pages.
 *
 * - Tracks time across multiple pages in a session
 * - Only counts time when tab is active
 * - Resets after 24 hours of inactivity
 * - Triggers analytics events at 10, 30, 60, 90, 180, 240, and 600 seconds
 * - Persists time data in sessionStorage
 * - Works with Next.js navigation and full page refresh
 */ var NS = LOCALSTORAGE_BASE_NAMESPACE;
var timerRef;
var trackTime = function() {
    var _ref = _async_to_generator(function(newTimeSpent) {
        var trackedIntervals, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, interval, err;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    trackedIntervals = JSON.parse(localStorage.getItem("".concat(NS, ".trackedIntervals")) || "[]");
                    _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                    _state.label = 1;
                case 1:
                    _state.trys.push([
                        1,
                        6,
                        7,
                        8
                    ]);
                    _iterator = TRACK_INTERVALS[Symbol.iterator]();
                    _state.label = 2;
                case 2:
                    if (!!(_iteratorNormalCompletion = (_step = _iterator.next()).done)) return [
                        3,
                        5
                    ];
                    interval = _step.value;
                    if (!(window.analytics && newTimeSpent >= interval && !trackedIntervals.includes(interval))) return [
                        3,
                        4
                    ];
                    return [
                        4,
                        window.analytics.track("".concat(interval, " seconds on site"), {
                            pathname: window.location.pathname,
                            time_spent: interval
                        })
                    ];
                case 3:
                    _state.sent();
                    trackedIntervals.push(interval);
                    localStorage.setItem("".concat(NS, ".trackedIntervals"), JSON.stringify(trackedIntervals));
                    _state.label = 4;
                case 4:
                    _iteratorNormalCompletion = true;
                    return [
                        3,
                        2
                    ];
                case 5:
                    return [
                        3,
                        8
                    ];
                case 6:
                    err = _state.sent();
                    _didIteratorError = true;
                    _iteratorError = err;
                    return [
                        3,
                        8
                    ];
                case 7:
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return != null) {
                            _iterator.return();
                        }
                    } finally{
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                    return [
                        7
                    ];
                case 8:
                    if (trackedIntervals.includes(TRACK_INTERVALS.at(-1))) {
                        clearInterval(timerRef);
                    }
                    return [
                        2
                    ];
            }
        });
    });
    return function trackTime(newTimeSpent) {
        return _ref.apply(this, arguments);
    };
}();
var updateTimeSpent = function() {
    var _ref = _async_to_generator(function() {
        var timeSpent, lastUpdated, sessionAge, newTimeSpent;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    // check if window is focused?
                    if (document.hidden || !document.hasFocus()) return [
                        2
                    ];
                    timeSpent = Number(localStorage.getItem("".concat(NS, ".timeSpent")) || 0);
                    lastUpdated = Number(localStorage.getItem("".concat(NS, ".timeSpentLastUpdated")) || 0);
                    sessionAge = Date.now() - lastUpdated;
                    // Reset session of 24h passed
                    if (lastUpdated != 0 && sessionAge > SESSION_DURATION) {
                        localStorage.removeItem("".concat(NS, ".timeSpent"));
                        localStorage.removeItem("".concat(NS, ".timeSpentLastUpdated"));
                        localStorage.removeItem("".concat(NS, ".trackedIntervals"));
                        return [
                            2
                        ];
                    }
                    if (Date.now() - lastUpdated < 990) return [
                        2
                    ];
                    newTimeSpent = timeSpent + 1;
                    localStorage.setItem("".concat(NS, ".timeSpent"), newTimeSpent.toString());
                    localStorage.setItem("".concat(NS, ".timeSpentLastUpdated"), Date.now().toString());
                    return [
                        4,
                        trackTime(newTimeSpent)
                    ];
                case 1:
                    _state.sent();
                    return [
                        2
                    ];
            }
        });
    });
    return function updateTimeSpent() {
        return _ref.apply(this, arguments);
    };
}();
export var TimeOnSiteTracking = function() {
    useEffect(function() {
        clearInterval(timerRef);
        timerRef = window.setInterval(updateTimeSpent, 1000);
        return function() {
            clearInterval(timerRef);
        };
    }, []);
    return null;
};
export default TimeOnSiteTracking;
