/* eslint @typescript-eslint/no-var-requires: "off" */ "use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _objectSpread = require("@swc/helpers/lib/_object_spread.js").default;
var _objectSpreadProps = require("@swc/helpers/lib/_object_spread_props.js").default;
var _toConsumableArray = require("@swc/helpers/lib/_to_consumable_array.js").default;
var defaultTheme = require("tailwindcss/defaultTheme");
module.exports = {
    content: [
        "./components/**/*.{js,ts,jsx,tsx}",
        "./design/**/*.{js,ts,jsx,tsx,mdx}",
        "./pages/**/*.{js,ts,jsx,tsx}", 
    ],
    theme: {
        colors: {
            transparent: "transparent",
            current: "currentColor",
            black: "#222222",
            grey: {
                light: "#EBE4E4",
                mid: "#B1A9A9",
                dark: "#817777",
                darker: "#6C6464",
                1000: "#6A5B5B",
                1100: "#4E3D3D"
            },
            beige: "#FAF4F4",
            white: "#FFFFFF",
            red: {
                50: "#FDECEA",
                100: "#F1D4D6",
                900: "#BA2934",
                1000: "#A11721"
            },
            orange: {
                100: "#F5D9D0",
                900: "#CC4115"
            },
            yellow: {
                100: "#FAE2D5",
                900: "#E86F2B"
            },
            pink: {
                100: "#F8D7DC",
                900: "#DA3552",
                input: "#EA00B0"
            },
            purple: {
                100: "#F1D1DE",
                900: "#BA175B",
                1000: "#9E144D",
                1100: "#790F3B"
            },
            green: {
                500: "#38AB51"
            }
        },
        fontFamily: {
            sans: [
                "Metropolis",
                "Metropolis-fallback", 
            ].concat(_toConsumableArray(defaultTheme.fontFamily.sans))
        },
        container: {
            padding: {
                DEFAULT: "1rem",
                sm: "2rem",
                lg: "5.25rem",
                xl: "5.25rem"
            }
        },
        boxShadow: {
            sm: "0px 1px 2px rgb(0 0 0 / 0.05)",
            DEFAULT: "0px 0px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1)",
            md: "0px 1px 4px -2px rgb(0 0 0 / 0.1), 0px 1px 6px -1px rgb(0 0 0 / 0.1)",
            lg: "0px 1px 6px -4px rgb(0 0 0 / 0.1), 0px 3px 15px -3px rgb(0 0 0 / 0.1)",
            xl: "0px 2px 10px -6px rgb(0 0 0 / 0.1), 0px 5px 25px -5px rgb(0 0 0 / 0.1)",
            "2xl": "0px 10px 50px -12px rgb(0 0 0 / 0.25)",
            inner: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
            panel: "0px 4px 40px rgba(0 0 0 / 0.1)",
            none: "none"
        },
        screens: _objectSpreadProps(_objectSpread({}, defaultTheme.screens), {
            "material-md": "960px"
        })
    },
    plugins: [
        require("@tailwindcss/forms"),
        require("@tailwindcss/container-queries"), 
    ]
};
