/**
 * Functions to set up an Apollo client that integrates with Auth0
 * authentication
 *
 * Borrows ideas from https://github.com/vercel/next.js/discussions/11957 and
 * https://github.com/auth0/nextjs-auth0/issues/69#issuecomment-596760312
 */
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  DocumentNode,
  OperationVariables,
  ServerError,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { captureException, captureMessage } from '@sentry/nextjs'
import fetch from 'isomorphic-unfetch'
import { useMemo } from 'react'

let apolloClient: ApolloClient<NormalizedCacheObject>
export let accessToken: string | undefined = undefined

/** Request an access token from the API */
export const requestAccessToken = async (): Promise<void> => {
  if (accessToken || typeof window === 'undefined') return

  // TODO: I suspect this will break on server rendering
  const res = await fetch('/api/auth/session')
  if (res.ok) {
    const json = await res.json()
    accessToken = json.accessToken
  } else {
    accessToken = undefined
  }
}

/** Link to add auth token to Authorization header */
const authLink = setContext(async (req, { headers }) => {
  // TODO: This is aggressive
  await requestAccessToken()
  if (!accessToken) {
    return {
      headers,
    }
  } else {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      },
    }
  }
})

/** Link to reset the token on server error */
const resetTokenLink = onError(({ networkError }) => {
  if (
    networkError &&
    networkError.name === 'ServerError' &&
    (networkError as ServerError).statusCode === 401
  ) {
    // reset access token if we get a 401 error
    accessToken = undefined
    networkError.message =
      'You are not authorized correctly. Please try logging out and logging back in again.'
  }
})

/** Link to notify sentry on server error */
const sentryLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      captureMessage(message, {
        extra: { locations, path },
      })
    })
  if (networkError) {
    captureException(networkError)
  }
})

/** Base HTTP link to connect Apollo to the GraphQL endpoint */
const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  fetch,
})

export function initializeApollo(
  initialState?: NormalizedCacheObject,
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient =
    apolloClient ||
    new ApolloClient({
      ssrMode: typeof window === 'undefined',
      link: authLink.concat(resetTokenLink).concat(sentryLink).concat(httpLink),
      cache: new InMemoryCache(),
    })

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

/** Hook to return an Apollo client for use in ApolloProvider */
export function useApollo(
  initialState?: NormalizedCacheObject,
): ApolloClient<NormalizedCacheObject> {
  const client = useMemo(() => initializeApollo(initialState), [initialState])
  return client
}

/**
 * Pre-cache the specified Apollo queries (e.g. in a Next.js getStaticProps
 * handler)
 *
 * @param queries Array of Apollo query DocumentNodes
 */
export async function preCacheApolloQueries(
  queries: (DocumentNode | [DocumentNode, OperationVariables])[],
): Promise<NormalizedCacheObject> {
  const apolloClient = initializeApollo()

  /** Iterate over the queries */
  for (const queryDoc of queries) {
    if (Array.isArray(queryDoc)) {
      const [query, variables] = queryDoc
      await apolloClient.query({ query, variables })
    } else {
      await apolloClient.query({ query: queryDoc })
    }
  }

  /** Return the extracted cache */
  return apolloClient.cache.extract()
}
