import { useGetFinancialPeriodsByPersonIdQuery } from '..'
import { flattenGQLArrayResult, makeQueryWithReducer } from '../utils'
import { useWithPerson } from '../utils/useWithPerson'

export * from './types'

export const useGetFinancialPeriodsByPersonIdQueryWithReducer =
  makeQueryWithReducer(
    useGetFinancialPeriodsByPersonIdQuery,
    (result) => flattenGQLArrayResult(result.data?.FinancialPeriods),
    useWithPerson,
  )
