export var FIELD_HEIGHT_PX = 56;
export var FIELD_HEIGHT_SMALL_PX = 48;
export var BG_STATUS_COLORS = {
    warning: "bg-yellow-900",
    success: "bg-green-500",
    error: "bg-red-900"
};
export var TEXT_STATUS_COLORS = {
    warning: "text-yellow-900",
    success: "text-green-500",
    error: "text-red-900"
};
