export var setUrlFragment = function(anchorId) {
    if ("object" !== "undefined") {
        window.history.replaceState(undefined, "", "#".concat(anchorId));
        window.dispatchEvent(new HashChangeEvent("hashchange"));
    }
};
export var smoothScrollOnClick = function(href) {
    return function(e) {
        e.preventDefault();
        var anchorElement = document.getElementById(href.substring(1));
        if (anchorElement) {
            anchorElement.scrollIntoView({
                behavior: "smooth"
            });
            setTimeout(function() {
                return setUrlFragment(href.substring(1));
            }, 700);
        }
    };
};
